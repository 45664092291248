@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
}

/* index.css or App.css */
@import url("https://unpkg.com/leaflet/dist/leaflet.css");

/* index.css or App.css */
html,
body {
  direction: rtl;
  text-align: right;
}

.container {
  direction: rtl;
  text-align: right;
}

body {
  font-family: "Almarai", sans-serif;
}
